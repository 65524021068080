
import { Component, Vue,Watch, Prop } from "vue-property-decorator";

@Component({
  name: "InfomationPage",
})
export default class InfomationPage extends Vue {
  @Prop() lang!: string;
  @Watch('$route', { immediate: true, deep: true })
         routeWatch(newVal: any): void {
            
            document.documentElement.scrollTop = 0
         }
}
